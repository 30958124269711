<script setup lang="ts">
import { required, email } from '@vuelidate/validators'
import type { VuelidateParserParams } from '~~/composables/useVuelidateParser'

const { auth } = useSupabaseClient()

const formFieldsDefinition: VuelidateParserParams = [
  { key: 'email', value: '', rules: { required, email } },
  { key: 'password', value: '', rules: { required } },
]

const showTokenButton = ref(false)

const handleLogin = async (formData: any) => {
  const { error } = await auth.signInWithPassword({
    email: formData.email,
    password: formData.password,
  })
  if (error)
    throw (
      error?.toString()?.replace('AuthApiError:', '') ??
      'Invalid credentials provided.'
    )
}

const onSubmitSuccess = () => {
  isLoading.value = true
  successMessage.value = 'Logging you in'
}

const { v$, submitForm, canSubmit, successMessage, isLoading } = useFormUtils({
  vuelidate: formFieldsDefinition,
  hooks: {
    onSubmit: handleLogin,
    onSubmitSuccess: onSubmitSuccess,
  },
})
</script>
<template lang="pug">
form(@submit.prevent="submitForm" class="form-tight")
  UiInput(
    v-model="v$.email.$model"
    type="email"
    placeholder="Company Email"
    autocomplete="email"
    :error="v$.email.$errors[0]?.$message"
  )
  div(class="flex flex-col lg:flex-row gap-9 relative")
    div(class="flex-1")
      UiInput(
        v-model="v$.password.$model"
        type="password"
        placeholder="Password"
        autocomplete="current-password"
        :error="v$.password.$errors[0]?.$message"
      )
    div(class="flex lg:flex-col justify-between items-center")
      button(
        class="btn btn-primary order-2"
        type="submit"
        :disabled="!canSubmit"
      )
        span Login
        MaterialSymbol arrow_right_alt

      NuxtLink(
        class="text-xs font-medium justify-center text-brand-900 md:p-16 lg:py-18 lg:absolute lg:left-0 lg:top-full"
        to="/login/password"
      ) Forgot your login?

  NuxtLink(
    v-if="showTokenButton"
    class="btn btn-primary"
    to="/login/token"
  ) Resend Verification Email
</template>
